import React, { useState, useEffect, useMemo } from "react";
import { withFirebase } from "../../../utils/Firebase";
import AddResource from "./atoms/AddResources";
import ResourceTable from "./atoms/ResourceTable";
import OutlinedButton from "../../atoms/OutlinedButton";
import MinistrySelect from "../../molecules/MinistrySelect";
import UniversalModal from "../../atoms/UniversalModal";

const Resources = ({ firebase }) => {
  const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  const [resourceCount, setResourceCount] = useState(0);
  const [currentResources, setCurrentResources] = useState([]);
  const [showCreateResource, setShowCreateResource] = useState(false);
  const [ministries, setMinistries] = useState([]);
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    if (currentUser) {
      firebase
        .resources()
        // .where("facilitators", "array-contains", currentUser.uid)
        .get()
        .then((querySnapshot) => {
          let array = [];
          let languageArray = [];
          querySnapshot.forEach((doc) => {
            const resourceData = doc.data();
            // doc.data() is never undefined for query doc snapshots
            array.push(resourceData);
            if (languageArray.indexOf(resourceData.language) === -1) {
              languageArray.push(resourceData.language);
            }
          });
          const sortedLanguages = languageArray.sort((a, b) => {
            const lNameA = a.split("--")[1];
            const lNameB = b.split("--")[1];
            return lNameA > lNameB ? 1 : lNameA === lNameB ? 0 : -1;
          });
          setCurrentResources(array || []);
          setAvailableLanguages(sortedLanguages);
        })
        .catch((err) => console.log(err));
      // getMinistries();
    }
  }, [currentUser, resourceCount, firebase, selectedMinistry]);

  const userResources = currentResources.filter(
    (r) => r.ministries.indexOf(selectedMinistry) !== -1
  );
  const nonUserResources = currentResources.filter(
    (r) => r.ministries.indexOf(selectedMinistry) === -1
  );

  return (
    <div className="home container">
      <div className="home__details">
        <h1 className="home__title">Bible Resources</h1>
      </div>
      <MinistrySelect
        selectedMinistry={selectedMinistry}
        setSelectedMinistry={setSelectedMinistry}
      />
      <div className="addResources__btnContainer">
        <OutlinedButton
          type="submit"
          // disabled={!selectedCountry || !isValid || !currentUser}
          onClick={() => setShowCreateResource(!showCreateResource)}
          text={showCreateResource ? "X CLOSE" : "+ ADD NEW RESOURCE"}
        />
      </div>
      {userResources.length === 0 ? (
        <p>
          What Bibles are you distributing?
          <br /> Please add a Bible resource to your account.
        </p>
      ) : (
        <div className="resources">
          <h4 className="addResources__header">CURRENT RESOURCES</h4>
          <ResourceTable
            resources={userResources || []}
            setResourceCount={setResourceCount}
            resourceCount={resourceCount}
            uid={currentUser.uid}
            ministryId={selectedMinistry}
            ministries={ministries}
          />
        </div>
      )}

      {showCreateResource && (
        <UniversalModal
          open={showCreateResource}
          setOpen={setShowCreateResource}
          title="Add Resource"
        >
          <AddResource
            nonUserResources={nonUserResources}
            availableLanguages={availableLanguages}
            setResourceCount={setResourceCount}
            resourceCount={resourceCount}
            ministryId={selectedMinistry}
            setShowCreateResource={setShowCreateResource}
          />
        </UniversalModal>
      )}
    </div>
  );
};

export default withFirebase(Resources);
