import React from "react";
import classNames from "classnames";

const Select = ({ options, labelName, className, value, setValue }) => {
  return (
    <div className={classNames("select", className)}>
      <select
        value={value}
        onChange={(event) => setValue(event.target.value)}
        className="select__element"
      >
        <option value={""}>Select</option>
        {options.map((opt, index) => (
          <option
            key={`${labelName}-${index}-${Math.random()}`}
            value={opt.value}
            style={opt.disabled ? { color: "grey", fontStyle: "italic" } : {}}
            disabled={!!opt.disabled}
          >
            {opt.label}
          </option>
        ))}
      </select>
      <span className="select__highlight" />
      <span className="select__bar" />
      <label className="select__label">{labelName}</label>
    </div>
  );
};

export default Select;
