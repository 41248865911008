import React, { useMemo } from "react";
import { withFirebase } from "../../../../utils/Firebase";
import { getPortion } from "../../../../functions/getPortion";
import { getLanguageName } from "../../../../functions/getLanguageName";

const ResourceTable = ({
  firebase,
  resources,
  setResourceCount,
  resourceCount,
  uid,
  ministryId,
  ministries,
}) => {
  const reportData = useMemo(() => {
    if (ministries && ministryId) {
      const currentMinistry = ministries.find(
        (m) => m.ministryId === ministryId
      );
      if (currentMinistry) {
        const { reportData } = currentMinistry;
        return reportData;
      }
      return null;
    }
    return null;
  }, [ministries, ministryId]);

  const removeResource = async (resourceId, facilitatorId, ministryId) => {
    await firebase.removeFacilitatorToResourceData(resourceId, facilitatorId);
    await firebase
      .removeMinistryToResourceData(resourceId, ministryId)
      .then(() => {
        setResourceCount(resourceCount + 1);
      });
  };

  const approveResource = async (resourceId) => {
    await firebase.approveResource(resourceId).then(() => {
      setResourceCount(resourceCount + 1);
    });
  };

  return (
    <table className="resources__table">
      <tbody>
        <tr className="resources__table__row">
          <th className="resources__table__cell">Name</th>
          <th className="resources__table__cell">Portion</th>
          <th className="resources__table__cell">Format</th>
          <th className="resources__table__cell">Language</th>
          <th className="resources__table__cell">Description</th>
          <th className="resources__table__cell">Submitted By</th>
          {reportData && reportData.resources && (
            <th className="resources__table__cell">Shared</th>
          )}
          <th className="resources__table__cell">Action</th>
        </tr>
        {resources.map((r, index) => {
          let submitter = ""
          // console.log("resource", r, r.submitter)
          // try {
          //     if (r.submitter) {
          //     const userData = await firebase
          //       .user(r.submitter)
          //       submitter = userData.email
          //     }
          //   }
          // catch (e) { }
          // finally {

          // }
        console.log("submitter", submitter);
        return (
          <tr
            key={`${r.resourceId}-${index}`}
            className="resources__table__row"
          >
            <td className="resources__table__cell">
              {r.approved && <span>✅ </span>}
              {r.name}
            </td>
            <td className="resources__table__cell">{getPortion(r.portion)}</td>
            <td className="resources__table__cell">{r.format}</td>
            <td className="resources__table__cell">
              {r.language.split("--")[1]}
            </td>
            <td className="resources__table__cell">{r.description}</td>
            <td className="resources__table__cell">
              {r.submitter || "unknown"}
            </td>
            {reportData && reportData.resources && (
              <td className="resources__table__cell">
                {reportData.resources[r.resourceId] || "-"}
              </td>
            )}
            <td className="resources__table__cell">
              <div
                className="resources__removeBtn__red"
                onClick={() => removeResource(r.resourceId, uid, ministryId)}
              >
                REMOVE
              </div>
              {!r.approved && (
                <div
                  className="resources__removeBtn__blue"
                  onClick={() => approveResource(r.resourceId, uid, ministryId)}
                >
                  APPROVE
                </div>
              )}
            </td>
          </tr>
        );
        })}
      </tbody>
    </table>
  );
};

export default withFirebase(ResourceTable);
