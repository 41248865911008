export const getPortion = (portion) => {
  switch (portion) {
    case "Whole":
      return "Whole Bible";
    case "NT":
      return "New Testament";
    case "Gospel":
      return "Gospel";
    case "Other":
      return "Other";
    default:
      return "Other";
  }
};
