import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import Resources from "../components/scenes/Resources";

const condition = (authUser) => !!authUser;
const ResourcesPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(Resources);

export default () => {
  return (
    <Layout>
      <ResourcesPage />
    </Layout>
  );
};
