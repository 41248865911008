import React, { useState } from "react";
import { withFirebase } from "../../../../utils/Firebase";
import Input from "../../../atoms/Input";
import Select from "../../../atoms/Select";
import Countries from "../../../../data/resourcesLanguageData/CountryCodes.json";
import { useEffect } from "react";
import Button from "../../../atoms/Button";
import Divider from "../../../atoms/Divider";
import { getPortion } from "../../../../functions/getPortion";
import { getLanguageName } from "../../../../functions/getLanguageName";

const countryOptions = Object.values(Countries).map((c) => {
  return {
    value: c.CountryID,
    label: c.Name,
  };
});

const AddResources = ({
  firebase,
  nonUserResources,
  availableLanguages,
  setResourceCount,
  resourceCount,
  ministryId,
  setShowCreateResource,
}) => {
  const { currentUser } = firebase.auth;
  const [name, setName] = useState("");
  const [country, setCountry] = useState(null);
  const [language, setLanguage] = useState("");
  const [langOpts, setLangOpts] = useState(null);
  const [format, setFormat] = useState("");
  const [portion, setPortion] = useState("");
  const [description, setDescription] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const isValid =
    !!currentUser.uid &&
    !!name &&
    !!country &&
    !!language &&
    !!format &&
    !!portion;

  useEffect(() => {
    if (country) {
      const countryData = require(`../../../../data/resourcesLanguageData/${country}.json`);
      const langOptions = countryData.map((l) => {
        const labelName = l.SBID.split("--")[1];
        return {
          value: l.SBID,
          label: labelName,
        };
      });
      setLangOpts(langOptions);
    }
  }, [country, firebase]);

  const createUniqueId = async () => {
    const resourceId = Array(28)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    const resourceIdExists = await firebase
      .resource(resourceId)
      .get()
      .then((doc) => doc.exists);
    while (resourceIdExists) {
      console.log(`uid ${resourceId} exists already!`);
      createUniqueId();
    }
    return resourceId;
  };

  const addAvailableResource = async (
    resourceID,
    facilitatorId,
    ministryId
  ) => {
    if (ministryId) {
      await firebase.addMinistryToResourceData(resourceID, ministryId);
    }
    await firebase
      .addFacilitatorToResourceData(resourceID, facilitatorId)
      .then(() => {
        alert("success, resource added");
        setResourceCount(resourceCount + 1);
        setShowCreateResource(false);
      })
      .catch((err) =>
        alert(
          `error [addFacilitatorToResourceData] ${resourceID}, ${facilitatorId}`
        )
      );
  };

  const saveResources = async () => {
    const resourceId = await createUniqueId().then((id) => id);
    const resourceData = {
      resourceId,
      facilitators: [currentUser.uid],
      name,
      language,
      country,
      format,
      portion,
      description,
      ministries: [ministryId],
    };
    await firebase
      .resource(resourceId)
      .set(resourceData)
      .then(() => {
        alert("success, resource added");
        setName("");
        setCountry("");
        setLanguage("");
        setFormat("");
        setPortion("");
        setDescription("");
        setLangOpts([]);
        setResourceCount(resourceCount + 1);
        setShowCreateResource(false);
        return;
      })
      .catch((err) => {
        alert("failed to save resource");
        return;
      });
  };

  return (
    <div>
      <div className="addResources__block">
        <h4 className="addResources__header">
          SELECT AVAILABLE BIBLE RESOURCE
        </h4>
        <div className="addResources__available">
          <Select
            options={availableLanguages.map((l) => {
              const langName = l.split("--")[1];
              return {
                value: l || "",
                label: langName,
              };
            })}
            labelName="Select Language"
            // className="addResources__languageContainer__select"
            value={selectedLanguage}
            setValue={setSelectedLanguage}
          />
          <Select
            options={nonUserResources
              .filter((res) => res.language === selectedLanguage)
              .map((r) => {
                const langName = r.language.split("--")[1];
                return {
                  value: r.resourceId || "",
                  label: `${r.name} (${langName}) - ${getPortion(r.portion)}, ${
                    r.format
                  }`,
                };
              })}
            labelName="Available Resources"
            // className="addResources__languageContainer__select"
            value={selectedResource}
            setValue={setSelectedResource}
          />
          <Button
            disabled={!selectedResource || !currentUser}
            onClick={() =>
              addAvailableResource(
                selectedResource,
                currentUser.uid,
                ministryId
              )
            }
            text="Add Resource"
          />
        </div>
      </div>
      {/* <Divider /> */}
      <div className="addResources__verticalDivider" />
      <div className="addResources__block">
        <h4 className="addResources__header">ADD A NEW BIBLE RESOURCE</h4>
        <div>
          <Input
            className="inputMarginBottom"
            labelName="Name"
            required={true}
            type="text"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </div>
        <div className="addResources__languageContainer">
          <div className="addResources__languageContainer__select">
            <Select
              options={countryOptions || []}
              labelName="Country"
              className="addResources__languageContainer__select"
              value={country}
              setValue={setCountry}
            />
          </div>
          <div className="addResources__languageContainer__select">
            <Select
              options={langOpts || []}
              labelName="Language"
              className="addResources__languageContainer__select"
              value={language}
              setValue={setLanguage}
            />
          </div>
        </div>
        <Select
          options={[
            { value: "print", label: "print" },
            { value: "audio", label: "audio" },
            { value: "digital", label: "digital" },
            { value: "video", label: "video" },
          ]}
          labelName="Format"
          className="addResources__languageContainer__select"
          value={format}
          setValue={setFormat}
        />
        <Select
          options={[
            { value: "Whole", label: "Whole Bible" },
            { value: "NT", label: "New Testament" },
            {
              value: "Gospel",
              label: "A Gospel (please describe which gospel in description)",
            },
            {
              value: "Other",
              label: "Other (please provide a description below)",
            },
          ]}
          labelName="Portion"
          className="addResources__languageContainer__select"
          value={portion}
          setValue={setPortion}
        />
        <Input
          className="inputMarginBottom"
          labelName="Description"
          required={true}
          type="textarea"
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
        <Button
          disabled={!isValid}
          onClick={() => saveResources()}
          text="Add Resource"
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default withFirebase(AddResources);
